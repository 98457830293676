export const RECOVERY_BRANDS_ACCOUNT_ID = '7k2ZxOfG';
export const MAX_LISTING_STORAGE = 20;

export const TOKEN_REFRESH_INTERVAL_MS = 4 * 60 * 1000;
export const MAX_PHOTO_SIZE_MB = 5;

export const CR_START_DATE = '2015-08-01';

export const EVENT_CATEGORIES = {
    Account: 'Account',
    Subaccount: 'Subaccount',
    Listing: 'Listing',
    Dashboard: 'Dashboard',
    Transactions: 'Transactions',
    Billing: 'Billing',
    Reviews: 'Reviews',
    // Editorials: 'Editorials',
    KnowledgeBase: 'Knowledge Base',
    VIP: 'VIP',
};

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const DAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const LISTING_TYPES = [
    'free',
    'claimed',
    'standard',
    'standard-op',
    'promoted',
    'promoted-op',
    'vip',
    'vip-op',
];
export const LISTING_TYPES_PAID = [
    'standard',
    'standard-op',
    'promoted',
    'promoted-op',
    'vip',
    'vip-op',
];
export const LISTING_TYPES_OUTPATIENT = ['standard-op', 'promoted-op', 'vip-op'];
export const LISTING_TYPES_DEPRECATED = [];

/**
 *
 * The maximum number of listings to fetch/display
 */
export const MAX_LISTINGS = 150;

export const TIMEZONES = [
    {
        display: 'Pacific (Los Angeles)',
        name: 'America/Los_Angeles',
    },
    {
        display: 'Eastern (New York)',
        name: 'America/New_York',
    },
    {
        display: 'Central (Chicago)',
        name: 'America/Chicago',
    },
    {
        display: 'Mountain (Denver)',
        name: 'America/Denver',
    },
    {
        display: 'Mountain - No DST (Phoenix)',
        name: 'America/Phoenix',
    },
    {
        display: 'Alaska (Anchorage)',
        name: 'America/Anchorage',
    },
    {
        display: 'Hawaii (Adak)',
        name: 'America/Adak',
    },
    {
        display: 'Hawaii - No DST (Honolulu)',
        name: 'Pacific/Honolulu',
    },
];

export const STATES = [
    {
        display: 'Alabama',
        name: 'AL',
    },
    {
        display: 'American Samoa',
        name: 'AS',
    },
    {
        display: 'Alaska',
        name: 'AK',
    },
    {
        display: 'Arizona',
        name: 'AZ',
    },
    {
        display: 'Arkansas',
        name: 'AR',
    },
    {
        display: 'California',
        name: 'CA',
    },
    {
        display: 'Colorado',
        name: 'CO',
    },
    {
        display: 'Connecticut',
        name: 'CT',
    },
    {
        display: 'Delaware',
        name: 'DE',
    },
    {
        display: 'Florida',
        name: 'FL',
    },
    {
        display: 'Micronesia',
        name: 'FM',
    },
    {
        display: 'Georgia',
        name: 'GA',
    },
    {
        display: 'Guam',
        name: 'GU',
    },
    {
        display: 'Hawaii',
        name: 'HI',
    },
    {
        display: 'Idaho',
        name: 'ID',
    },
    {
        display: 'Illinois',
        name: 'IL',
    },
    {
        display: 'Indiana',
        name: 'IN',
    },
    {
        display: 'Iowa',
        name: 'IA',
    },
    {
        display: 'Kansas',
        name: 'KS',
    },
    {
        display: 'Kentucky',
        name: 'KY',
    },
    {
        display: 'Louisiana',
        name: 'LA',
    },
    {
        display: 'Maine',
        name: 'ME',
    },
    {
        display: 'Maryland',
        name: 'MD',
    },
    {
        display: 'Massachusetts',
        name: 'MA',
    },
    {
        display: 'Marshall Islands',
        name: 'MH',
    },
    {
        display: 'Michigan',
        name: 'MI',
    },
    {
        display: 'Minnesota',
        name: 'MN',
    },
    {
        display: 'Northern Marianas',
        name: 'MP',
    },
    {
        display: 'Mississippi',
        name: 'MS',
    },
    {
        display: 'Missouri',
        name: 'MO',
    },
    {
        display: 'Montana',
        name: 'MT',
    },
    {
        display: 'Nebraska',
        name: 'NE',
    },
    {
        display: 'Nevada',
        name: 'NV',
    },
    {
        display: 'New Hampshire',
        name: 'NH',
    },
    {
        display: 'New Jersey',
        name: 'NJ',
    },
    {
        display: 'New Mexico',
        name: 'NM',
    },
    {
        display: 'New York',
        name: 'NY',
    },
    {
        display: 'North Carolina',
        name: 'NC',
    },
    {
        display: 'North Dakota',
        name: 'ND',
    },
    {
        display: 'Ohio',
        name: 'OH',
    },
    {
        display: 'Oklahoma',
        name: 'OK',
    },
    {
        display: 'Oregon',
        name: 'OR',
    },
    {
        display: 'Pennsylvania',
        name: 'PA',
    },
    {
        display: 'Puerto Rico',
        name: 'PR',
    },
    {
        display: 'Palau',
        name: 'PW',
    },
    {
        display: 'Rhode Island',
        name: 'RI',
    },
    {
        display: 'South Carolina',
        name: 'SC',
    },
    {
        display: 'South Dakota',
        name: 'SD',
    },
    {
        display: 'San Juan',
        name: 'SJ',
    },
    {
        display: 'Tennessee',
        name: 'TN',
    },
    {
        display: 'Texas',
        name: 'TX',
    },
    {
        display: 'Utah',
        name: 'UT',
    },
    {
        display: 'Virgin Islands',
        name: 'VI',
    },
    {
        display: 'Vermont',
        name: 'VT',
    },
    {
        display: 'Virginia',
        name: 'VA',
    },
    {
        display: 'Washington',
        name: 'WA',
    },
    {
        display: 'Washington, D.C.',
        name: 'DC',
    },
    {
        display: 'West Virginia',
        name: 'WV',
    },
    {
        display: 'Wisconsin',
        name: 'WI',
    },
    {
        display: 'Wyoming',
        name: 'WY',
    },
];

export const ENVIRONMENTAL_SETTINGS = [
    'Average Location/Amenities',
    'Beach Community',
    'Desert',
    'Executive',
    'Hospital',
    'Lakeside',
    'Luxury',
    'Mountains',
    'Oceanfront',
    'Private/Secluded',
    'Residential Neighborhood',
    'Resort',
    'Wilderness',
    /**
     * Matching Database Values
     */
    'Ultra-Luxury',
];

export const FAMILY_PROGRAMMING = [
    'Al ANON',
    'Couples Therapy',
    'Family Therapy',
    'Family Events',
];

export const LICENSING = [
    /**
     * Business Requirements
     */
    'CARF',
    'Joint Commission',
    'NAATP',
];

export const SERVICES = [
    'Behavioral Disorder Treatment',
    'Detox Services',
    'Dual-Diagnosis Treatment',
    'Inpatient',
    'Intensive Outpatient Services',
    'Outpatient Services',
    'Residential Treatment',
    'Sober Living Home',
    'Telehealth',
];

export const ACCOMMODATIONS = [
    'Computer/Phone/Internet Access',
    'Gourmet/Chef-Prepared Meals',
    'Pet Friendly',
    'Private Rooms',
    'Smoking Permitted',
];

export const GENDERS = [
    /**
     * Business Requirements
     */
    // 'LGBTQ-Specific Programs',
    // 'Men Only Treatment Programs',
    // 'No Gender Restrictions',
    // 'Separate Treatment Programs for Men and Women',
    // 'Women Only Treatment Programs',

    /**
     * Match Database Values
     */
    'Exceptionally LGBT Friendly',
    'Men Only',
    'No Gender Restrictions',
    'Offers Co-ed Programs',
    'Women Only',
];

export const RECREATIONS = [
    'Animal Therapy',
    'Art Therapy',
    'Dance Therapy',
    'Drama Therapy',
    'Exercise Classes',
    'Gym',
    'Meditation',
    'Music Therapy',
    'Pilates',
    'Outdoor Training (Running, Hiking, Cycling)',
    'Yoga',
];

export const TREATMENTS = [
    'Anger Disorders',
    'Anxiety Disorder',
    'Attention Deficit Hyperactive Disorder (ADHD)',
    'Bipolar Disorder Treatment',
    'Depressive Disorders',
    'Eating Disorder Treatment',
    'Gambling Addiction Treatment',
    'Gaming Addiction Treatment',
    'Internet Addiction Treatment',
    'Obsessive-Compulsive Disorder (OCD)',
    'Schizophrenia Treatment',
    'Shopping Addiction Treatment',
    'Substance Abuse Treatment',
    'Porn Addiction Treatment',
    'Post-Traumatic Stress Disorder (PTSD)',
];

export const TREATMENT_METHODS = [
    'MAT (medically assisted treatment)',
    'Abstinence',
    '12 step',
    'Evidence based',
];

export const AGES = ['Adult Age', 'Senior/Elderly', 'Teen/Adolescent'];

export const INSURANCES = [
    //'Blue Cross Blue Shield',
    //'Blue Shield',
    '1199 National Benefit Fund (SEIU)',
    'Aetna',
    'Allied Trades',
    'Ambetter',
    'Anthem',
    'Anthem BCBS',
    'Baylor Scott & White',
    'BCBS of MS',
    'Beacon Health Options',
    'Blue Cross and Blue Shield',
    'Bright Health',
    'Carisk Behavioral Health',
    'Cigna',
    'Compsych',
    'Concern Plus',
    'Coventry',
    'Devon',
    'Empire BCBS',
    'Employers Health Network',
    'Federal 104/105/106',
    'Federal 110/111/112',
    'First Health',
    'Friday Health Plans',
    'GHI/Emblem',
    'Halcyon Behavioral',
    'Health Care Corporation',
    'Health First Health Plan',
    'Health Net',
    'Health Plan of NV/Sierra Health/BHO',
    'Highmark',
    'HMC HealthWorks',
    'Hometown Health',
    'Humana',
    'Insurance Not Accepted',
    'Kaiser',
    'Kaiser Permanente',
    'Magellan',
    'Magellan Behavioral Health',
    'Magellan Health Services',
    'MagnaCare',
    'Medicaid',
    'Medicare',
    'Meritain Health',
    'MHN',
    'Modern Assistance Programs (MAPS)',
    'Molina',
    'Optum/UHC',
    'Oscar Health Plan',
    'Private Insurance',
    'Private Pay VA',
    'Prominence Health Plan',
    'Qualcare',
    'Quest Behavioral Health',
    'Rocky Mountain HMO Colorado',
    'Schaller Anderson Aetna Better Health of NY',
    'Schaller Anderson Aetna Better Health of Ohio',
    'Seminole Tribe/Miccosukee Tribe',
    'State Insurance',
    'TRICARE',
    'TRICARE Certified',
    'TRICARE East',
    'TRICARE West',
    'Tufts Health Plan',
    'UnitedHealthcare',
    'UMR',
    'Uprise Health',
    'Veteran Affairs (VA Health Insurance)',
    'Wellpoint',
];

export const getEditorialTemplate = (
    listingName,
) => `**ABOUT ${listingName.toUpperCase()}**

Begin paragraph one... **Here's a sentence I'd like to be bold.**

**TREATMENT & ASSESSMENT**

Paragraph one might have a reference to [SMART Recovery](http://www.smartrecovery.org/).

**STAFF CREDENTIALS**

**ACCOMMODATIONS & AMENITIES**

**WHAT ALUMNI SAY**

**WHAT FRIENDS & FAMILY SAY**

When citing a reputable third-party reviews site, insert a numerical citation after the quote.
For example, alum Denise wrote on www.YellowPages.com: "Never would I consider a different hospital for any of
my needs or my family's needs." [^1]

**WHAT STAFF SAY**

**FINANCING _or_ CONCLUSION**

[^1]: [http://www.yellowpages.com/indianapolis-in/mip/iu-health-methodist-hospital-2589631?lid=2589631](http://www.yellowpages.com/indianapolis-in/mip/iu-health-methodist-hospital-2589631?lid=2589631)
`;

export const REFUND_REASONS = [
    'manual-credit-card-refund-cancelled-campaign',
    'manual-credit-card-refund-wrong-amount',
];

export const DASHBOARD_SPECIFIER = '%Y-%m-%d';
