'use client';
import {STATES} from 'core/constants';
import React from 'react';
import {useParams} from 'next/navigation';

export const get = (obj, keys) =>
    keys.split('.').reduce((acc, key) => {
        return acc == null ? acc : acc[key]; // eslint-disable-line
    }, obj);

export const isArray = (obj) => {
    if (typeof obj !== 'object' || obj === null) return false;

    return obj.hasOwnProperty('length');
};

export const isEmpty = (value) => {
    if (typeof value === 'undefined' || value === null) return true;
    if (value instanceof Date) return false;
    switch (typeof value) {
        case 'boolean':
            return false;
        case 'string':
            return value.length === 0;
        case 'object':
            if (isArray(value)) {
                return value.length === 0;
            }

            return Object.keys(value).length === 0;
        default:
            return false;
    }
};

export const aOrAn = (str) => {
    if (typeof str !== 'string') {
        console.warn('Must pass a string value');
        return 'a';
    }

    return ['a', 'e', 'i', 'o', 'u'].includes(str.slice(0, 1)) ? 'an' : 'a';
};

export const isNullable = (value) => {
    if (typeof value === 'undefined' || value === null) return true;
    if (value instanceof Date) return false;
    return false;
};

export const omit = (obj, keys) => {
    if (!obj) {
        console.warn('Cannot omit keys from', typeof obj, obj);
        return {};
    }

    if (!keys) {
        return obj;
    }

    let arr =
        typeof keys === 'string' || typeof keys === 'number' ? [keys.toString()] : keys;

    return Object.assign(
        {},
        Object.keys(obj).reduce((acc, cur) => {
            if (arr.indexOf(cur) === -1) {
                acc[cur] = obj[cur];
            }
            return acc;
        }, {}),
    );
};

/**
 *
 * Recursively omit nullable/empty values (null, undefined, {})
 * option "keysToOmit" excludes object keys
 *
 * omitRecurse({str: 'visible', omit: 'gone', removed: null}, 'omit')
 * => {str: 'visible'}
 */
export const omitRecursive = (obj, ...keysToOmit) => {
    if (!obj) return console.warn('Must pass an object to omit keys from', obj);

    return Object.keys(obj).reduce((acc, key) => {
        // Return accumulator if key matches keysToOmit
        if (keysToOmit.includes(key)) return acc;

        const value = obj[key];
        // Remove null, undefined
        if (isNullable(value)) return acc;
        // Add to accumulator if type is not equal to object or array
        if (typeof value !== 'object') {
            acc[key] = value;
            return acc;
        }

        // If array of objects
        if (isArray(value)) {
            const reduceArray = function (arr) {
                return value.reduce(function (acc, v) {
                    if (isEmpty(v)) return acc;

                    if (typeof v !== 'object' && !isArray(v)) {
                        acc.push(v);

                        return acc;
                    }

                    if (typeof v === 'object' && !isEmpty(v)) {
                        // should recurse
                        const omitted = omitRecursive(v, ...keysToOmit);
                        if (!isEmpty(omitted)) {
                            acc.push(omitted);
                        }

                        return acc;
                    }

                    acc.push(omitRecursive(v, ...keysToOmit));

                    return acc;
                }, []);
            };

            // Check to see if array is now empty
            const arrayReduced = reduceArray(value);
            //if (!isEmpty(arrayReduced)) {
            acc[key] = arrayReduced;
            //}

            return acc;
        }

        const omitted = omitRecursive(value, ...keysToOmit);
        if (!isEmpty(omitted)) {
            acc[key] = omitRecursive(value, ...keysToOmit);
        }

        return acc;
    }, {});
};

export const include = (obj, keys) => {
    if (!keys) return obj;
    if (!obj || typeof obj !== 'object') {
        console.warn('Cannot `include` properties of', obj);
        return {};
    }

    let arr =
        typeof keys === 'string' || typeof keys === 'number' ? [keys.toString()] : keys;

    return Object.assign(
        {},
        Object.keys(obj).reduce((acc, cur) => {
            if (arr.indexOf(cur) !== -1) {
                acc[cur] = obj[cur];
            }
            return acc;
        }, {}),
    );
};

export const lower = (str) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.toLowerCase();
};

export const cleanUpEmail = (email) => {
    return lower(email).trim();
};

export const cleanUp2FACode = (code) => {
    return typeof code === 'string' ? code.toUpperCase().trim() : code;
};

const zeroPad = (str) => {
    if (typeof str === 'string') {
        return str.length === 1 ? 0 + str : str;
    } else if (typeof str === 'number') {
        return str.toString().length === 1 ? 0 + str.toString() : str.toString();
    }
    return str;
};

export const sortAscending = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export const sortDescending = (a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
};

export const sortNum = (a, b) => {
    const x = parseInt(a, 10);
    const y = parseInt(b, 10);

    if (x === 0 && y === 0) return 1 / y - 1 / x || 0;

    return y - x;
};

export function sortArrayByKey(subscriptions, keys) {
    return subscriptions.sort(function (a, b) {
        const textA = get(a, keys)?.toUpperCase();
        const textB = get(b, keys)?.toUpperCase();

        const secondTernary = textA > textB ? 1 : 0;
        return textA < textB ? -1 : secondTernary;
    });
}

export const intersection = (a = [], b = []) => {
    return a.filter((x) => b.includes(x));
};

export const capitalize = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalCase = (str) => {
    if (!str) return '';

    if (typeof str !== 'string') {
        return console.warn('Cannot capitalize ', str, ', not of type "string"');
    }

    /**
     * snake-case to Capital Case
     */
    if (str.match(/-/gi)) {
        return str.split('-').reduce((acc, cur, i) => {
            if (i === 0) return acc + capitalize(cur);
            return acc + ' ' + capitalize(cur);
        }, '');
    }

    if (str.match(/\s/gi)) {
        return str.split(' ').reduce((acc, cur, i) => {
            if (i === 0) return acc + capitalize(cur);
            return acc + ' ' + capitalize(cur);
        }, '');
    }

    return capitalize(str);
};

export const camelToCapital = (str) => {
    if (typeof str !== 'string') return '';

    const spacesAdded = [...str].reduce((acc, letter) => {
        if (letter !== letter.toLowerCase()) {
            acc.push(' ');
        }
        acc.push(letter);
        return acc;
    }, []);

    return capitalCase(spacesAdded.join(''));
};

export const classReducer = (obj) => {
    return Object.keys(obj).reduce((acc, cur) => {
        if (obj[cur]) {
            acc += cur + ' ';
        }

        return acc;
    }, '');
};

export const checkArrayEquality = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.reduce((acc, cur, i) => {
        return acc && cur === arr2[i];
    }, true);
};

export const getListingType = (listing) => {
    if (typeof listing !== 'object' || !listing) return '';

    const value = listing.type || listing.listing_type;

    if (!value) return null;

    const currentListingType = typeof value === 'string' ? value : value.name;

    if (!currentListingType) {
        console.warn('Cannot get listing type', listing);
    }

    return currentListingType;
};

export const getListingName = (listing) => {
    if (!listing) return '';

    return listing.name || listing.listing_name || '';
};

export const formatPhone = (str) => {
    if (!str) return '';
    if (typeof str !== 'string') {
        console.warn('Phone number not of type string', str);
        return '';
    }

    const extension = str.length === 11 && str.slice(-12, -11) === '1' ? '1' : '';

    return `${extension} (${str.slice(-10, -7)}) ${str.slice(-7, -4)}-${str.slice(-4)}`;
};

export const formatSeconds = (seconds) => {
    if (!seconds) return '';

    const num = parseInt(seconds);

    if (num < 60) return `${seconds} seconds`;

    if (num > 60 && num < 60 * 60) {
        const minutes = Math.floor(num / 60);
        const seconds = num % 60;
        return `${minutes} minute${minutes === 1 ? '' : 's'} ${
            seconds ? seconds + ' second' : ''
        }${seconds && seconds !== 1 ? 's' : ''}`;
    }

    return num + ' seconds';
};

export const getEventType = (transaction) => {
    const eventType =
        transaction.transaction_source || transaction.tracking_type || transaction.source;

    if (eventType === 'stripe') {
        return 'Credit';
    }

    if (eventType === 'RbInternal') {
        return 'Internal';
    }

    return eventType;
};

export const getEventId = (transaction) => {
    if (!transaction) return console.warn('Cannot get transaction ID from ', transaction);
    if (!transaction.transaction_id && !transaction.tracking_id) {
        console.warn(
            'Cannot key transaction without tracking or transaction id',
            transaction,
        );
        return '';
    }

    if (transaction.transaction_id && transaction.tracking_id) {
        return transaction.transaction_id + transaction.tracking_id;
    } else if (transaction.transaction_id && !transaction.tracking_id) {
        return transaction.transaction_id;
    }

    return transaction.tracking_id;
};

export const getFormattedDispute = (str) => {
    if (!str) {
        console.warn('Cannot format dispute', str);

        return '';
    }

    if (!str.match('-')) return str;

    return str
        .split('-')
        .slice(1)
        .map((w) => capitalCase(w))
        .join(' ');
};

export const formatNumber = (num) => {
    if (typeof num !== 'number' && !num) return;

    let str = typeof num === 'string' ? num : num.toString();

    const split = str.split('').reverse();

    const commasAdded = split
        .reduceRight((acc, cur, i) => {
            acc.push(cur);

            if (cur === '-') {
                return acc;
            }

            if (i !== 0 && i % 3 === 0) {
                acc.push(',');
            }

            return acc;
        }, [])
        .join('');

    return commasAdded;
};

export const formatDollars = (str, showDollarSign = true) => {
    if (!str && typeof str !== 'number') {
        console.warn('Cannot format dollar amount', str);
        return '';
    }

    if (typeof str !== 'string' && typeof str !== 'number') {
        console.warn('Must pass string to format dollars', str);
        return '';
    }

    if (typeof str === 'string' && /(\$|,)/.test(str)) {
        return str;
    }

    if (isNaN(str)) {
        return '$';
    }

    const split = str.toString().split('.');

    let dollars = split[0];
    let cents = null;

    if (split.length > 2) {
        console.warn('Invalid dollar amount', str);
        return '';
    }

    if (split.length === 2) {
        cents = split[1].slice(0, 2);

        if (cents[0] !== 0 && cents.length === 1) {
            cents = cents + 0;
        }
    }

    const commasAdded = formatNumber(dollars);

    const isNegative = commasAdded.slice(0, 1) === '-';

    const hasCents = cents && cents !== '00';

    if (isNegative) {
        return `${showDollarSign ? '-$' : '-'}${
            hasCents ? commasAdded.slice(1) + '.' + cents : commasAdded.slice(1)
        }`;
    }

    return `${showDollarSign ? '$' : ''}${
        hasCents ? commasAdded + '.' + cents : commasAdded
    }`;
};

export const normalizeDollars = (val) => {
    let sanitized = val;

    if (/\./.test(val)) {
        sanitized = val.split('.') && val.split('.')[0];
    }

    return parseInt(val.replace(/[^0-9]/g, ''));
};

export const shallowIncludeChanged = (values, valuesInState) => {
    return Object.keys(values).reduce((acc, key) => {
        if (typeof values[key] === 'object') {
            return acc;
        }

        if (values[key] !== valuesInState[key]) {
            acc[key] = values[key];
        }

        return acc;
    }, {});
};

/**
 * @param values = object
 * @param mask = object
 *
 * Check to see whether or not values
 * exists as same value in mask
 * if so - do not include in result
 * if not - include in result
 */

// values => new
// mask => old
export const includeChanged = (values, mask) => {
    return Object.keys(values).reduce((acc, key) => {
        if (isEmpty(values[key])) return acc;

        if (isArray(values[key]) && isArray(mask[key])) {
            if (values[key].length !== mask[key].length) {
                acc[key] = values[key];

                return acc;
            }

            const hasDifference = values[key].find((val, i) => {
                if (!mask[key] || !mask[key][i]) return false;

                return val !== mask[key][i];
            });

            if (hasDifference) {
                acc[key] = values[key];
            }

            return acc;
        }

        if (typeof values[key] === 'object' && typeof mask[key] === 'object') {
            const recursed = includeChanged(values[key], mask[key]);

            if (!isEmpty(recursed)) {
                acc[key] = includeChanged(values[key], mask[key]);
            }

            return acc;
        }

        if (typeof values[key] === 'boolean') {
            if (values[key] === mask[key]) return acc;

            acc[key] = values[key];

            return acc;
        }

        if (typeof values[key] === 'number') {
            if (values[key] === mask[key]) return acc;
        }

        if (mask[key] && mask[key] === values[key]) return acc;

        acc[key] = values[key];

        return acc;
    }, {});
};

/**
 * Converts e.g., phone_number to Phone Number
 */
export const formatFieldName = (str) => {
    if (!str) {
        console.warn('Cannot format field', str);
        return '';
    }

    if (!str.match('_')) return capitalCase(str);

    const split = str.split('_');

    return split.reduce((acc, cur, i) => {
        if (i === 0) {
            acc += capitalCase(cur);
            return acc;
        }

        acc += ' ' + capitalCase(cur);

        return acc;
    }, '');
};

export const getListingCount = (account) => {
    if (!account) return 0;

    if (!account.hasOwnProperty('listings') && !account.hasOwnProperty('listing_count')) {
        console.warn('Account does not have listings property', account);
        return 0;
    }

    if (account.listings) {
        return account.listings.length;
    }

    if (account.listing_count) return account.listing_count;

    return 0;
};

export const getNodeWidth = (node) => {
    if (!node) return null;

    return node.clientWidth || node.getBoundingClientRect().width;
};

export const getNodeHeight = (node) => {
    if (!node) return null;

    return node.clientHeight || node.getBoundingClientRect().height;
};

export const getDetailedError = (data) => {
    if (!data) return '';

    if (
        data.errors &&
        data.errors.exception &&
        isArray(data.errors.exception) &&
        data.errors.exception.length > 0
    ) {
        const exceptionReduced = data.errors.exception.reduce((acc, cur) => {
            const endsWithPeriod = cur.slice(-1) === '.';
            const formatted = endsWithPeriod ? cur : cur + '.';
            return acc + formatted;
        }, '');

        return exceptionReduced;
    }

    return data.message || '';
};

export const getFieldError = (data) => {
    try {
        if (!data.errors || isEmpty(data.errors) || !data.errors.fields) {
            return getDetailedError(data);
        }

        if (typeof data.errors.fields !== 'object') return getDetailedError(data);

        const test = {highlights: ['A total of 9 highlights can be added']};

        return Object.keys(data.errors.fields).reduce((acc, cur) => {
            const val = data.errors.fields[cur];
            let field = cur.replace(/\[[1-9]+\]?/, '');
            if (isArray(val)) {
                acc += field + ': ';
                val.forEach((v) => {
                    acc += v + ' ';
                });
            } else if (!isArray(val) && typeof val === 'object') {
                acc += field + ' ';
                Object.keys(val).forEach((key) => {
                    let childKey = key.replace(/\[[1-9]+\]?/, '');
                    acc += childKey;
                    if (isArray(val[key])) {
                        const strReduced = val[key].forEach((val) => {
                            acc += ': ' + val + ' ';
                        });
                    } else if (typeof field[key] === 'string') {
                        acc += val[key] + ' ';
                    }
                });
            } else {
                acc += val;
            }

            return acc;
        }, '');
    } catch (e) {
        console.warn(e);
        return 'Could not edit listing';
    }
};

export const parsePhoto = (file, formatted = true) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        try {
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                if (formatted) {
                    return resolve(file.name.replace(/\s+/g, '_') + ';' + reader.result);
                }

                return resolve(reader.result);
            };
        } catch (e) {
            console.warn('Could not upload photo', e.target);
        }
    });
};

export const convertPhotosToBase64 = (value) => {
    if (value === undefined || value === null) return Promise.resolve(value);
    if (typeof value !== 'object') return Promise.resolve(value);

    if (value instanceof File) return parsePhoto(value);

    if (isArray(value)) return Promise.all(value.map(convertPhotosToBase64));

    return Object.keys(value).reduce((accP, key) => {
        return accP.then((acc) => {
            return convertPhotosToBase64(value[key]).then((res) => {
                acc[key] = res;
                return acc;
            });
        });
    }, Promise.resolve({}));
};

export const shortenUrl = (str) => {
    if (typeof str !== 'string') {
        console.log('Cannot shorten non-string', str);
        return '';
    }

    if (str.length > 40) {
        return str.slice(0, 40) + '...';
    }

    return str;
};

export const formatPricing = (obj) => {
    const dollars = formatDollars(obj.pricing);

    let num = 0;

    switch (obj.display_as) {
        case 'day':
            num = parseInt(obj.days);
            break;
        case 'week':
            num = parseInt(obj.days) / 7;
            break;
        case 'month':
            num = parseInt(obj.days) / 30;
            break;
        default:
            num = (obj.days && parseInt(obj.days)) || 0;
    }

    return `${dollars} for ${num} ${obj.display_as}${num > 1 ? 's' : ''}`;
};

export const isBool = (val) => {
    return typeof val === 'boolean';
};

export const isNum = (val) => {
    return typeof val === 'number';
};

export function isPrimitive(val) {
    return val == null || /^[sbn]/.test(typeof val); // eslint-disable-line
}

/**
 * Converts an object to query parameters
 * @param {object} obj - The object to convert to a string
 * @param {boolean} ignoreNullValues - whether or not to include null values in string result
 */
export function queryStringConverter(obj, ignoreNullValues = false) {
    if (typeof obj !== 'object') {
        console.warn('Must pass an object to convert', obj);
        return '';
    }

    const keys = Object.keys(obj);

    if (!keys.length) return '';

    return keys.reduce((acc, key, i) => {
        const val = obj[key];

        if (val === null && ignoreNullValues) return acc;
        if (val === '' && ignoreNullValues) return acc;
        if (val === undefined) return acc;
        if (isArray(val) && isEmpty(val)) return acc;

        if (acc === '') {
            acc += '?';
        } else {
            acc += '&';
        }

        if (isPrimitive(val) && typeof val === 'string') {
            acc += `${key}=${encodeURI(val)}`;
        } else if (isPrimitive(val)) {
            acc += `${key}=${val}`;
        } else if (isArray(val)) {
            acc += `${key}=${val}`;
        }

        return acc;
    }, '');
}

export const includes = (arr, key) => {
    if (!arr || !isArray(arr)) return false;

    return arr.includes(key);
};

export const getRehabsPath = (listing) => {
    if (!listing || isEmpty(listing.domain_slugs)) {
        return 'https://rehabs.com';
    }

    return get(listing.domain_slugs[0], 'link').replace('www.', '');
};

export const makePossessive = (str) => {
    if (typeof str !== 'string') return '';

    const lastChar = str.slice(-1);

    if (lastChar === 's') {
        return str + "'"; // eslint-disable-line
    } else {
        return str + "'s"; // eslint-disable-line
    }
};

export function formatDisputeReason(str) {
    const reason = str.slice(26);

    const lower = reason.split('-').join(' ');

    return capitalCase(lower);
}

export const findMatch = (arr, values) => {
    return arr.filter((obj) => {
        if (!obj) return false;

        const keyIntersection = intersection(Object.keys(obj), Object.keys(values));

        if (isEmpty(keyIntersection)) return true;

        return keyIntersection.reduce((acc, key) => {
            let currentMatch;

            if (typeof values[key] === 'string') {
                if (values[key] === 'true') {
                    currentMatch = obj[key] === true;
                } else if (values[key] === 'false') {
                    currentMatch = obj[key] === false;
                } else {
                    currentMatch = lower(obj[key]).match(values[key]);
                }
            } else if (typeof values[key] === 'boolean') {
                currentMatch = obj[key] === values[key];
            } else if (isArray(values[key])) {
                currentMatch = values[key].includes(obj[key]);
            } else if (typeof values[key] === 'number') {
                currentMatch = obj[key] === values[key];
            } else if (values[key] && typeof values[key] === 'object') {
                currentMatch = filterSearchResults([obj[key]], values[key]).length;
            } else {
                console.warn('Cannot filter by ', key, 'property.');
                currentMatch = true;
            }

            return acc && currentMatch;
        }, true);
    });
};

export function filterSearchResults(arr, inputs) {
    if (isEmpty(inputs)) return arr;

    const values = Object.keys(inputs).reduce((acc, cur) => {
        if (isEmpty(inputs[cur])) return acc;

        if (typeof inputs[cur] === 'string') {
            acc[cur] = lower(inputs[cur]);
            return acc;
        } else if (isArray(inputs[cur])) {
            acc[cur] = inputs[cur].map((val) => lower(val));
            return acc;
        } else {
            acc[cur] = inputs[cur];
            return acc;
        }
    }, {});

    return findMatch(arr, values);
}

export const getStateName = (abbr) => {
    const match = STATES.find((s) => s.name === abbr);

    if (match) {
        return match.display;
    }

    return '';
};

export const convertPercentToRate = (percent) => {
    const int = +percent;

    if (typeof int !== 'number' || isNaN(int)) {
        console.warn('Cannot convert ' + int + ' to a rate');
        return 1;
    }

    return int / 100;
};

export const convertRateToPercent = (rate) => {
    const int = +rate;

    if (typeof int !== 'number' || isNaN(int)) {
        console.warn('Cannot convert ' + int + ' to a percent');
        return 100;
    }

    if (int === 0) return int;

    return Math.round((int - 1) * 100);
};

export const formatListingTypeDisplayName = (str) => {
    if (typeof str !== 'string') return '';

    if (str.toLowerCase() === 'vip') {
        return 'VIP';
    }

    if (str.toLowerCase() === 'vip listing') {
        return 'VIP Listing';
    }

    if (str.toLowerCase() === 'vip-op') {
        return 'VIP (Outpatient only)';
    }

    if (str.toLowerCase().endsWith('-op')) {
        return capitalCase(str.replace('-op', '')) + ' (Outpatient only)';
    }

    return capitalCase(str);
};

export const maybeIncludeAnalytics = (condition, action) => {
    if (condition) {
        return action;
    } else {
        return omit(action, 'meta');
    }
};

/*
 * This function was deprecated in react-router-dom, so, I had to wrap the functionality in this function
 * @param ComponentWithRouter
 * @returns {function(*)}
 */
export const withRouter = (ComponentWithRouter) => (props) => {
    // const location = useLocation();
    // const match = {params: useParams()};
    // const navigate = useNavigate();
    // const history = {
    //     back: () => navigate(-1),
    //     goBack: () => navigate(-1),
    //     location,
    //     push: (url, state) => navigate(url, {state}),
    //     replace: (url, state) => navigate(url, {replace: true, state}),
    // };
    const params = useParams();
    const match = {params: params};

    return (
        <ComponentWithRouter
            // location={location}
            match={match}
            // navigate={navigate}
            // history={history}
            {...props}
        />
    );
};

export const getReduxFormSubmitProps = (props) => {
    const reduxFormSubmitProps = [
        'submitting',
        'submitSucceeded',
        'submitFailed',
        'error',
        'valid',
        'disabled',
        'pristine',
        'dirty',
    ];
    return reduxFormSubmitProps.reduce((accumulator, prop) => {
        if (prop in props) {
            accumulator[prop] = props[prop];
            return accumulator;
        } else {
            return accumulator;
        }
    }, {});
};

export const filterByUniqueName = (data) => {
    const nameSet = new Set();
    return data.filter((item) => {
        const isUnique = !nameSet.has(item.name);
        nameSet.add(item.name);
        return isUnique;
    });
};
